import { useSelector } from "react-redux";
import moment from "moment-timezone";
import "moment/locale/ro";
moment.locale();

const AppointmentReview = ({ lang, reschedule, booking }) => {
  const slots = useSelector((state) => state.slots);
  const personalInformation = useSelector((state) => state.personalInformation);
  return (
    <div>
      <div className="flex justify-between mb-6">
        <h3 className="font-semibold text-xl">
          {reschedule === false ? (
            <>
              {personalInformation.lastName} {personalInformation.firstName}
            </>
          ) : (
            <>
              {booking?.pacient_last_name} {booking?.pacient_first_name}
            </>
          )}
        </h3>
        <span className="font-semibold text-xl">
          {lang === "ro"
            ? moment
                .utc(slots.selectedSlot?.start_time)
                .tz("Europe/Bucharest")
                .locale("ro")
                .format("dddd, DD MMMM YYYY, HH:mm")
            : moment
                .utc(slots.selectedSlot?.start_time)
                .tz("Europe/Bucharest")
                .locale("en")
                .format("dddd, DD MMMM YYYY, HH:mm")}
        </span>
      </div>
      {reschedule === false ? (
        <div>
          <div>
            <span className="text-gray-600 text-sm">
              {personalInformation.region}
            </span>
          </div>
          <div>
            <span className="text-gray-600 text-sm">
              {personalInformation.city} {personalInformation.address}
            </span>
          </div>
          <div className="flex gap-10">
            <span className="text-gray-600 text-sm">
              {lang === "ro" ? "Telefon:" : "Phone:"}{" "}
              {personalInformation.phone}
            </span>
            <span className="text-gray-600 text-sm">
              E-mail: {personalInformation.email}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AppointmentReview;
