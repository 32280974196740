import SidebarContent from "./SidebarContent";
const SidebarDesktop = ({ lang }) => {
  return (
    <div className="hidden lg:fixed lg:top-16 lg:bottom-0 lg:z-40 lg:flex lg:w-80 lg:flex-col">
      <div className="flex grow flex-col gap-y-2 overflow-y-auto border-r border-gray-200 bg-white p-6">
        <SidebarContent lang={lang} />
      </div>
    </div>
  );
};

export default SidebarDesktop;
