/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from "react";
// import { createUser, getUser } from "../api/user.js";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const value = localStorage.getItem("value");
    setUser({
      value: value,
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
