import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const isValidCNP = (cnp) => {
  if (cnp === null || cnp.length !== 13 || !/^\d+$/.test(cnp)) {
    return false;
  }

  // Constants
  var constantKey = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];

  // Extract year, month, and day
  var year = parseInt(cnp.slice(1, 3), 10);
  var month = parseInt(cnp.slice(3, 5), 10);
  var day = parseInt(cnp.slice(5, 7), 10);

  // Determine century of birth
  if (["1", "2"].includes(cnp[0])) {
    year += 1900;
  } else if (["3", "4"].includes(cnp[0])) {
    year += 1800;
  } else if (["5", "6", "7", "8"].includes(cnp[0])) {
    year += 2000;
  } else {
    return false;
  }

  // Validate date
  try {
    new Date(year, month - 1, day);
  } catch (error) {
    return false;
  }

  // Checksum calculation
  let checksum =
    constantKey.reduce((acc, val, i) => acc + parseInt(cnp[i]) * val, 0) % 11;
  checksum = checksum === 10 ? 1 : checksum;

  return checksum === parseInt(cnp.slice(-1), 10);
};

const countDigits = (str) => {
  const digitRegex = /\d/g; // Regular expression to match digits
  const matches = str.match(digitRegex); // Matching digits in the string
  return matches ? matches.length : 0; // Returning the count of digits
};

const NextPrevButtons = ({ currentStep, lang, onSave }) => {
  const { id } = useParams();
  const slots = useSelector((state) => state.slots);
  const personalInformation = useSelector((state) => state.personalInformation);
  const navigate = useNavigate();
  const goToReview = (e) => {
    e.preventDefault();
    if (
      personalInformation.firstName === "" ||
      personalInformation.firstName === null ||
      personalInformation.lastName === "" ||
      personalInformation.lastName === null ||
      personalInformation.CNP === "" ||
      personalInformation.CNP === null ||
      personalInformation.phone === "" ||
      personalInformation.phone === null ||
      personalInformation.email === "" ||
      personalInformation.email === null ||
      personalInformation.region === "" ||
      personalInformation.region === null
    ) {
      toast.error(
        lang === "ro"
          ? "Va rugam revalidati campurile!"
          : "Please revalidate the fields!"
      );
      return;
    }

    if (!isValidCNP(personalInformation.CNP)) {
      toast.error(
        lang === "ro"
          ? "Va rugam introduceti un CNP valid!"
          : "Please enter a valid CNP!"
      );
      return;
    }
    // if(personalInformation.countryCodePhone === "ro" || personalInformation.countryCodePhone === "") {
    if (
      personalInformation.countryCodePhone === "ro" &&
      countDigits(personalInformation.phone) != 11
    ) {
      toast.error(
        lang === "ro"
          ? "Va rugam introduceti un numar de telefon valid!"
          : "Please enter a valid phone number!"
      );
      return;
    }
    navigate(lang === "ro" ? "/review" : "/en/review");
  };
  return (
    <div className="flex justify-between">
      <div>
        {currentStep === 2 ? (
          <Link
            to={lang === "ro" ? "/" : "/en"}
            className="bg-blue-500 text-white px-4 py-1.5 rounded text-sm hover:bg-blue-600"
          >
            {lang === "ro" ? "Pasul anterior" : "Previous step"}
          </Link>
        ) : (
          ""
        )}
        {currentStep === 3 ? (
          <Link
            // to={lang === "ro" ? "/personal" : "/en/personal"}
            to={
              id === undefined
                ? lang === "ro"
                  ? "/personal"
                  : "/en/personal"
                : lang === "ro"
                ? "/rescheduling/" + id + "/dashboard"
                : "/en/rescheduling/" + id + "/dashboard"
            }
            className="bg-blue-500 text-white px-4 py-1.5 rounded text-sm hover:bg-blue-600"
          >
            {lang === "ro" ? "Pasul anterior" : "Previous step"}
          </Link>
        ) : (
          ""
        )}
      </div>
      <div>
        {currentStep === 1 ? (
          slots.selectedSlot === null ? (
            <button
              type="button"
              className="bg-gray-500 text-white px-4 py-1.5 rounded text-sm"
            >
              {lang === "ro" ? "Pasul urmator" : "Next step"}
            </button>
          ) : (
            <Link
              to={
                id === undefined
                  ? lang === "ro"
                    ? "/personal"
                    : "/en/personal"
                  : lang === "ro"
                  ? "/rescheduling/" + id + "/review"
                  : "/en/rescheduling/" + id + "/review"
              }
              className="bg-blue-500 text-white px-4 py-1.5 rounded text-sm hover:bg-blue-600"
            >
              {lang === "ro" ? "Pasul urmator" : "Next step"}
            </Link>
          )
        ) : (
          ""
        )}
        {currentStep === 2 ? (
          <button
            type="button"
            onClick={(e) => goToReview(e)}
            className="bg-blue-500 text-white px-4 py-1.5 rounded text-sm hover:bg-blue-600"
          >
            {lang === "ro" ? "Pasul urmator" : "Next step"}
          </button>
        ) : (
          ""
        )}
        {currentStep === 3 ? (
          <button
            type="button"
            onClick={onSave}
            className="bg-blue-500 text-white px-4 py-1.5 rounded text-sm hover:bg-blue-600"
          >
            {lang === "ro" ? "Programeaza" : "Book appointment"}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NextPrevButtons;
