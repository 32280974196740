import axios from "axios";

export const getAllPackages = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/packages`);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getMedicalUsers = async (package_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/slots/users`,
      {
        params: {
          package_id: package_id,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getSlots = async (package_id, user_id = null) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/slots`, {
      params: {
        package_id: package_id,
        user_id: user_id,
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const createAppointment = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/appointments`,
      data
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getBooking = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/bookings/${id}`,
      {
        params: {
          // package_id: package_id,
          // user_id: user_id,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const cancelBooking = async (id) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_DEV_HOST}/bookings/cancel`,
      {
        uuid: id,
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const rescheduleBooing = async (data) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_DEV_HOST}/bookings/reschedule`,
      data
    );
    return res;
  } catch (err) {
    return err.response;
  }
};
