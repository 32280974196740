import { useSelector } from "react-redux";
import moment from "moment-timezone";
import "moment/locale/ro";
const SidebarContent = ({ lang }) => {
  const slots = useSelector((state) => state.slots);
  const packages = useSelector((state) => state.packages);
  return (
    <>
      <div className="font-semibold">
        {lang === "ro" ? "Rezumat programare" : "Appointment summary"}
      </div>
      <div>
        <span className="text-xl font-semibold">
          Total: {parseInt(slots.selectedSlot ? slots.selectedSlot?.value : 0)}{" "}
          lei
        </span>
      </div>
      <div className="flex flex-col gap-y-5">
        {slots.selectedSlot !== null ? (
          <div className="text-sm">
            <h4 className="font-semibold mb-2">
              {lang === "ro" ? "Data" : "Date"}
            </h4>
            <div className="flex justify-between">
              <span>{lang === "ro" ? "Data:" : "Date:"}</span>
              <span>
                {moment
                  .utc(slots.selectedSlot.start_time)
                  .tz("Europe/Bucharest")
                  .locale(lang === "ro" ? "ro" : "en")
                  .format("dddd, DD MMMM YYYY")}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{lang === "ro" ? "Ora:" : "Time:"}</span>
              <span>
                {moment
                  .utc(slots.selectedSlot.start_time)
                  .tz("Europe/Bucharest")
                  .locale("ro")
                  .format("HH:mm")}
              </span>
            </div>
          </div>
        ) : null}
        {slots.selectedSlot !== null ? (
          <div className="text-sm">
            <h4 className="font-semibold mb-2">Doctor</h4>
            <div className="flex justify-between">
              <span>{slots.selectedSlot.user_name}</span>
            </div>
          </div>
        ) : null}
        {packages.selectedPackageId !== null ? (
          <div className="text-sm">
            <h4 className="font-semibold mb-2">
              {lang === "ro" ? "Pachet" : "Package"}
            </h4>
            <div className="flex justify-between">
              <span>
                {
                  packages.packages.find(
                    (el) => el.id === packages.selectedPackageId
                  ).name
                }
              </span>
              {slots.selectedSlot ? (
                <span>{parseInt(slots.selectedSlot?.value)} lei</span>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SidebarContent;
