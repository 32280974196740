import { useDispatch } from "react-redux";
import Header from "../components/Header";
import SidebarDesktop from "../components/SidebarDesktop";
import { purgeDoctors } from "../features/doctors/DoctorsSlice";
import { purgePackages } from "../features/packages/PackagesSlice";
import { purgeSlots } from "../features/slots/SlotsSlice";
import { purgePersonalInformation } from "../features/personalInformation/PersonalInformationSlice";
import { useEffect } from "react";
import { purgeTimestamp } from "../features/expireStates/ExpireStatesSlice";

const Finish = ({ lang, reschedule }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(purgeDoctors());
    dispatch(purgePackages());
    dispatch(purgeSlots());
    dispatch(purgePersonalInformation());
    dispatch(purgeTimestamp());
  }, [dispatch]);
  return (
    <>
      <Header lang={lang} />
      <div>
        <SidebarDesktop lang={lang} />

        <main className="lg:pl-72 mt-16">
          <div className="px-4 sm:px-6 lg:px-8 py-20">
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <div className="text-sm">
                <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                  {reschedule === true
                    ? lang === "ro"
                      ? "Anulare finalizata"
                      : "Appointment cancelled"
                    : lang === "ro"
                    ? "Va multumim pentru programarea efectuata!"
                    : "Thank you for your appointment!"}
                  {/* {lang === "ro"
                    ? "Va multumim pentru programarea efectuata!"
                    : "Thank you for your appointment!"} */}
                </h3>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Finish;
