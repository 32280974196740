import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  purgePackages,
  setPackages,
  setSelectedPackageId,
} from "../features/packages/PackagesSlice";
import {
  purgeDoctors,
  setDoctors,
  setSelectedDoctorId,
} from "../features/doctors/DoctorsSlice";
import {
  clearSelectedSlot,
  purgeSlots,
  setSlots,
} from "../features/slots/SlotsSlice";
import {
  getAllPackages,
  getBooking,
  getMedicalUsers,
  getSlots,
} from "../api/General";
import Header from "../components/Header";
import Steps from "../components/Steps";
import "./Calendar.css";
import moment from "moment-timezone";
import "moment/locale/ro";
import "react-datepicker/dist/react-datepicker.css";
import SidebarDesktop from "../components/SidebarDesktop";
import NextPrevButtons from "../components/NextPrevButtons";
import AdditionalInformation from "../components/Dashboard/AdditionalInformation";
import Calendar from "../components/Dashboard/Calendar";
import SelectDoctor from "../components/Dashboard/SelectDoctor";
import SelectPackage from "../components/Dashboard/SelectPackage";
import { setTimestamp } from "../features/expireStates/ExpireStatesSlice";
import {
  purgePersonalInformation,
  setMessage,
} from "../features/personalInformation/PersonalInformationSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

moment.locale();

const DashboardRO = ({ lang }) => {
  const packages = useSelector((state) => state.packages);
  const expireStates = useSelector((state) => state.expireStates);
  const [loadingDoctors, setLoadingDoctors] = useState(false);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(id !== undefined);
  const [booking, setBooking] = useState(null);
  useEffect(() => {
    if (id !== undefined) {
      setLoading(true);
      setLoadingDoctors(true);
      setLoadingSlots(true);
      getBooking(id).then((res) => {
        if (res.status !== 200) {
          toast.error(
            lang === "ro"
              ? "Programarea nu a fost gasita"
              : "Appointment not found"
          );
        } else {
          setBooking(res.data);
          dispatch(setMessage(res.data?.online_obs));

          dispatch(setSelectedPackageId(res.data?.prices?.package?.id));
          dispatch(setSelectedDoctorId(res.data?.id));

          getMedicalUsers(res.data?.prices?.package?.id).then((resu) => {
            dispatch(setDoctors(resu.data));
            dispatch(setSelectedDoctorId(res.data?.id));
            setLoadingDoctors(false);
            setLoading(false);
          });
          getSlots(res.data?.prices?.package?.id, res.data?.id).then((resi) => {
            dispatch(setSlots(resi.data));
            setLoadingSlots(false);
          });
        }
      });
    }
    if (expireStates.timestamp === null) {
      dispatch(setTimestamp(new Date().getTime()));
    } else {
      const now = new Date().getTime();
      const diff = now - expireStates.timestamp;
      if (diff > 60 * 60 * 1000) {
        dispatch(setTimestamp(new Date().getTime()));
        dispatch(purgeDoctors());
        dispatch(purgePackages());
        dispatch(purgeSlots());
        dispatch(purgePersonalInformation());
        navigate("/");
      }
    }
    const fetchData = () => {
      getAllPackages().then((res) => {
        dispatch(setPackages(res.data));
      });
    };
    fetchData();
  }, [dispatch]);

  const onSelectPackage = (package_id) => {
    setLoadingDoctors(true);
    setLoadingSlots(true);
    dispatch(setSelectedPackageId(package_id));
    dispatch(setSelectedDoctorId(null));
    dispatch(clearSelectedSlot());
    getSlots(package_id).then((res) => {
      dispatch(setSlots(res.data));
      setLoadingSlots(false);
    });
    getMedicalUsers(package_id).then((res) => {
      dispatch(setDoctors(res.data));
      setLoadingDoctors(false);
    });
  };

  const onSelectMedicalUser = (e) => {
    setLoadingSlots(true);
    const user_id = e?.value;
    dispatch(setSelectedDoctorId(user_id));
    dispatch(clearSelectedSlot());
    getSlots(packages.selectedPackageId, user_id).then((res) => {
      dispatch(setSlots(res.data));
      setLoadingSlots(false);
    });
  };
  return (
    <>
      <Header lang={lang} />
      <div>
        <SidebarDesktop lang={lang} />

        <main className="lg:pl-72 mt-16 pb-6 lg:pb-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {/* first div */}
            <section className="m-7 p-7 max-w-6xl mx-auto">
              <Steps currentStep={1} lang={lang} />
            </section>
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <SelectPackage
                    onSelectPackage={onSelectPackage}
                    lang={lang}
                  />
                  {packages.selectedPackageId === null ? (
                    <p className="mt-6 text-gray-600 text-sm">
                      {lang === "ro"
                        ? "Alegeți pachetul pentru a continua"
                        : "Choose a package to continue"}
                    </p>
                  ) : null}
                </>
              )}
            </section>
            <section
              className={
                "m-7 p-7 bg-white max-w-6xl mx-auto " +
                (packages.selectedPackageId === null ? "hidden" : "")
              }
            >
              <SelectDoctor
                onSelectMedicalUser={onSelectMedicalUser}
                loadingDoctors={loadingDoctors}
                lang={lang}
              />
            </section>

            <section
              className={
                "m-7 p-7 bg-white max-w-6xl mx-auto " +
                (packages.selectedPackageId === null ? "hidden" : "")
              }
            >
              <Calendar loadingSlots={loadingSlots} lang={lang} />
            </section>

            <section
              className={
                "m-7 p-7 bg-white max-w-6xl mx-auto " +
                (packages.selectedPackageId === null ? "hidden" : "")
              }
            >
              <AdditionalInformation lang={lang} />
            </section>
            <section
              className={
                "m-7 p-7 bg-white max-w-6xl mx-auto " +
                (packages.selectedPackageId === null ? "hidden" : "")
              }
            >
              <NextPrevButtons currentStep={1} lang={lang} />
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default DashboardRO;
