import "./App.css";
import DashboardRO from "./pages/DashboardRO";
import Personal from "./pages/Personal";
import { Route, Routes } from "react-router-dom";
import Review from "./pages/Review";
import Finish from "./pages/Finish";
import Cancel from "./pages/Cancel";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardRO lang={"ro"} />} />
      <Route path="/en" element={<DashboardRO lang={"en"} />} />
      <Route path="/personal" element={<Personal lang={"ro"} />} />
      <Route path="/en/personal" element={<Personal lang={"en"} />} />
      <Route
        path="/review"
        element={<Review lang={"ro"} reschedule={false} />}
      />
      <Route
        path="/en/review"
        element={<Review lang={"en"} reschedule={false} />}
      />
      <Route
        path="/finish"
        element={<Finish lang={"ro"} reschedule={false} />}
      />
      <Route
        path="/en/finish"
        element={<Finish lang={"en"} reschedule={false} />}
      />
      <Route path="/cancel/:id" element={<Cancel lang={"ro"} />} />
      <Route path="/en/cancel/:id" element={<Cancel lang={"en"} />} />
      <Route
        path="/rescheduling/:id/dashboard"
        element={<DashboardRO lang={"ro"} />}
      />
      <Route
        path="/en/rescheduling/:id/dashboard"
        element={<DashboardRO lang={"en"} />}
      />
      <Route
        path="/rescheduling/:id/review"
        element={<Review lang={"ro"} reschedule={true} />}
      />
      <Route
        path="/en/rescheduling/:id/review"
        element={<Review lang={"en"} reschedule={true} />}
      />
      <Route
        path="/cancelled"
        element={<Finish lang={"ro"} reschedule={true} />}
      />
      <Route
        path="/en/cancelled"
        element={<Finish lang={"en"} reschedule={true} />}
      />
    </Routes>
  );
};

export default App;
