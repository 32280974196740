import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import packagesReducer from "./features/packages/PackagesSlice";
import doctorsReducer from "./features/doctors/DoctorsSlice";
import slotsReducer from "./features/slots/SlotsSlice";
import personalInformationReducer from "./features/personalInformation/PersonalInformationSlice";
import expireStatesReducer from "./features/expireStates/ExpireStatesSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const packagesPersistConfig = {
  key: "packages",
  storage,
};

const doctorsPersistConfig = {
  key: "doctors",
  storage,
};

const slotsPersistConfig = {
  key: "slots",
  storage,
};

const personalInformationConfig = {
  key: "personalInformation",
  storage,
};

const expireStatesConfig = {
  key: "expireStates",
  storage,
};

const persistedPackagesReducer = persistReducer(
  packagesPersistConfig,
  packagesReducer
);
const persistedDoctorsReducer = persistReducer(
  doctorsPersistConfig,
  doctorsReducer
);

const persistedPersonalInformationReducer = persistReducer(
  personalInformationConfig,
  personalInformationReducer
);

const persistedSlotsReducer = persistReducer(slotsPersistConfig, slotsReducer);

const persistedExpireStatesReducer = persistReducer(
  expireStatesConfig,
  expireStatesReducer
);

const store = configureStore({
  reducer: {
    packages: persistedPackagesReducer,
    doctors: persistedDoctorsReducer,
    slots: persistedSlotsReducer,
    personalInformation: persistedPersonalInformationReducer,
    expireStates: persistedExpireStatesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };

// export default configureStore({
//   reducer: {
//     packages: packagesReducer,
//     doctors: doctorsReducer,
//   },
// });
