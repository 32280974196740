import { createSlice } from "@reduxjs/toolkit";

export const packagesSlice = createSlice({
  name: "packages",
  initialState: {
    packages: [],
    selectedPackageId: null,
  },
  reducers: {
    setPackages: (state, action) => {
      return {
        ...state,
        packages: action.payload,
      };
    },
    setSelectedPackageId: (state, action) => {
      return {
        ...state,
        selectedPackageId: action.payload,
      };
    },
    purgePackages: (state) => {
      return {
        ...state,
        packages: [],
        selectedPackageId: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPackages, setSelectedPackageId, purgePackages } =
  packagesSlice.actions;

export default packagesSlice.reducer;
