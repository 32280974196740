import { createSlice } from "@reduxjs/toolkit";

export const doctorsSlice = createSlice({
  name: "doctors",
  initialState: {
    value: {
      doctors: [],
      selectedDoctorId: null,
    },
  },
  reducers: {
    setDoctors: (state, action) => {
      return {
        ...state,
        value: {
          ...state.value,
          doctors: action.payload,
        },
      };
    },
    setSelectedDoctorId: (state, action) => {
      return {
        ...state,
        value: {
          ...state.value,
          selectedDoctorId: action.payload,
        },
      };
    },
    purgeDoctors: (state) => {
      return {
        ...state,
        value: {
          doctors: [],
          selectedDoctorId: null,
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDoctors, setSelectedDoctorId, purgeDoctors } =
  doctorsSlice.actions;

export default doctorsSlice.reducer;
