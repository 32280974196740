import { createSlice } from "@reduxjs/toolkit";

export const personalInformationSlice = createSlice({
  name: "personalInformation",
  initialState: {
    firstName: "",
    lastName: "",
    CNP: "",
    email: "",
    phone: "",
    countryCodePhone: "",
    region: "",
    city: "",
    address: "",
    message: "",
  },
  reducers: {
    setFirstName: (state, action) => {
      return {
        ...state,
        firstName: action.payload,
      };
    },
    setLastName: (state, action) => {
      return {
        ...state,
        lastName: action.payload,
      };
    },
    setEmail: (state, action) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    setPhone: (state, action) => {
      return {
        ...state,
        phone: action.payload.phone,
        countryCodePhone: action.payload.countryCode,
      };
    },
    setRegion: (state, action) => {
      return {
        ...state,
        region: action.payload,
      };
    },
    setCity: (state, action) => {
      return {
        ...state,
        city: action.payload,
      };
    },
    setAddress: (state, action) => {
      return {
        ...state,
        address: action.payload,
      };
    },
    setCNP: (state, action) => {
      return {
        ...state,
        CNP: action.payload,
      };
    },
    setMessage: (state, action) => {
      return {
        ...state,
        message: action.payload,
      };
    },
    purgePersonalInformation: (state) => {
      return {
        ...state,
        firstName: "",
        lastName: "",
        CNP: "",
        email: "",
        phone: "",
        region: "",
        city: "",
        address: "",
        message: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setRegion,
  setCity,
  setAddress,
  setCNP,
  setMessage,
  purgePersonalInformation,
} = personalInformationSlice.actions;

export default personalInformationSlice.reducer;
