import Header from "../components/Header";
import Steps from "../components/Steps";
import moment from "moment-timezone";
import SidebarDesktop from "../components/SidebarDesktop";
import NextPrevButtons from "../components/NextPrevButtons";
import PersonalDataAbstract from "../components/Personal/PersonalDataAbstract";
import PersonalDataFields from "../components/Personal/PersonalDataFields";
import { setTimestamp } from "../features/expireStates/ExpireStatesSlice";
import { purgeDoctors } from "../features/doctors/DoctorsSlice";
import { purgePackages } from "../features/packages/PackagesSlice";
import { purgeSlots } from "../features/slots/SlotsSlice";
import { purgePersonalInformation } from "../features/personalInformation/PersonalInformationSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

moment.locale();
const Personal = ({ lang }) => {
  const expireStates = useSelector((state) => state.expireStates);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (expireStates.timestamp === null) {
      dispatch(setTimestamp(new Date().getTime()));
    } else {
      const now = new Date().getTime();
      const diff = now - expireStates.timestamp;
      if (diff > 60 * 60 * 1000) {
        dispatch(setTimestamp(new Date().getTime()));
        dispatch(purgeDoctors());
        dispatch(purgePackages());
        dispatch(purgeSlots());
        dispatch(purgePersonalInformation());
        navigate("/");
      }
    }
  }, []);
  return (
    <>
      <Header lang={lang} />
      <div>
        <SidebarDesktop lang={lang} />
        <main className="lg:pl-72 mt-16">
          <div className="px-4 sm:px-6 lg:px-8">
            <section className="m-7 p-7 max-w-6xl mx-auto">
              <Steps currentStep={2} lang={lang} />
            </section>
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <div className="text-sm">
                <PersonalDataAbstract lang={lang} />
                <PersonalDataFields lang={lang} />
              </div>
            </section>
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <NextPrevButtons currentStep={2} lang={lang} />
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Personal;
