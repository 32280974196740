import { useSelector } from "react-redux";

const ReviewTable = ({ lang }) => {
  const slots = useSelector((state) => state.slots);
  const packages = useSelector((state) => state.packages);
  return (
    <table className="w-full">
      <thead className="bg-[#f7f4f4]">
        <tr>
          <th
            colSpan={3}
            className="text-left p-1.5 border border-[#ccc] text-sm font-semibold"
          >
            {lang === "ro" ? "DESCRIERE" : "DESCRIPTION"}
          </th>
          <th className="text-left p-1.5 border border-[#ccc] text-sm font-semibold">
            {lang === "ro" ? "PRET" : "PRICE"}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={3} className="text-left p-2  border border-[#ccc]">
            <span className="font-semibold">
              {
                packages.packages.find(
                  (el) => el.id === packages.selectedPackageId
                )?.name
              }
            </span>
            <br></br>
            <span>Doctor: {slots.selectedSlot?.user_name}</span>
          </td>
          <td className="p-2 border border-[#ccc] text-left ">
            {parseInt(slots.selectedSlot?.value)} lei
          </td>
        </tr>
        <tr className="bg-[#fffcce]">
          <td
            className="text-right font-semibold p-2 border border-[#ccc]"
            colSpan={3}
          >
            TOTAL
          </td>
          <td className="font-semibold p-2 border border-[#ccc]">
            {parseInt(slots.selectedSlot?.value)} lei
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReviewTable;
